import React from 'react'
import { graphql } from 'gatsby'
import { connect } from 'react-redux'
import SEO from '../widget/seo'
import Layout, { Section } from '../widget/layout'
import { renderMdx } from '../helper/mdx'
import { Grid } from '../ui'
import { ContentBock } from '../ui/typography'

const PageLayout = ({ data, auth, pageContext, ...rest }) => {
  console.log(pageContext)
  const { page: { title, body } } = pageContext

  return (
    <Layout>
      <SEO title={title}/>
      <Section>
        <Grid withPageConstraints>
          <Grid.Col width={[12, 10, 8]} widthOffset={[0, 1, 2]}>
            <ContentBock>
              {renderMdx(body)}
            </ContentBock>
          </Grid.Col>
        </Grid>
      </Section>
    </Layout>
  )
}
PageLayout.propTypes = {}

export default connect(state => ({
  auth: state.auth,
}))(PageLayout)
